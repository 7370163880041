import * as React from 'react';

const Cover = ({ markdown, imageUrl }) => {
  return (
    <div
      className="flex items-center h-[100vh] shadow bg-cover"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      <div className="absolute inset-0 bg-black z-0 opacity-50"></div>
      <div className="max-w-screen-lg w-full mx-auto z-0">
        <div
          className="max-w-[500px] mx-auto sm:mx-0 px-4 text-center md:text-left text-white text-xl md:text-2xl"
          style={{
            textShadow: '0px 2px 3px #000',
          }}
          dangerouslySetInnerHTML={{
            __html: markdown.childMarkdownRemark.html,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Cover;
