import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Cover from '../components/cover';
import Layout from '../components/layout';

const IndexPage = () => {
  const { allContentfulBlogPost, contentfulPage } = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { order: ASC, fields: createdAt }) {
        nodes {
          content {
            childMarkdownRemark {
              html
            }
          }
          title
        }
      }
      contentfulPage(slug: { eq: "home" }) {
        content {
          childMarkdownRemark {
            html
          }
        }
        coverContent {
          childMarkdownRemark {
            html
          }
        }
        coverImage {
          file {
            url
          }
        }
        id
        slug
        title
      }
    }
  `);

  return (
    <Layout title={contentfulPage.title}>
      <Cover
        markdown={contentfulPage.coverContent}
        imageUrl={contentfulPage.coverImage?.file.url}
      />
      <div
        className="prose mt-8 px-4 lg:px-0 mx-auto max-w-screen-xl"
        dangerouslySetInnerHTML={{
          __html: contentfulPage.content?.childMarkdownRemark?.html,
        }}
      />
      <div className="mt-10 mx-auto max-w-screen-xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {allContentfulBlogPost.nodes.map((post) => (
            <div className="border rounded shadow p-3 mx-3 md:mx-0">
              <h2 className="mt-1 mb-2 text-xl font-bold">{post.title}</h2>
              <div
                className="prose lg:px-0 mx-auto max-w-screen-xl"
                dangerouslySetInnerHTML={{
                  __html: post.content?.childMarkdownRemark?.html,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
